<template>
  <!-- <base-layout :noPageHeader="noPageHeader" :toolbarClass="toolbarClass" :headerClass="headerClass" :pageDefaultBackLink="pageDefaultBackLink"> -->
  <base-layout-2
    :noPageHeader="false"
    :toolbarClass="'home'"
    :toolbarColor="'primary'"
    :headerClass="'fixed-home'"
    :pageTitle="lang.settings.title"
    :contentColor="'primary'"
    :pageDefaultBackLink="'/home'"
    @content="setIonContent"
  >
    <template v-slot:ion-content>
      <div class="page-container ion-padding">
        <template v-if="settings">
          <ion-list class="exchanges">
            <ion-list-header
              ><h4>{{ lang.settings.general }}</h4></ion-list-header
            >
            <ion-item>
              <ion-label>{{
                lang.signal_notifications ??
                "Signal notifications"
              }}</ion-label>
              <ion-toggle
                slot="end"
                color="primary"
                @ionChange="setBuyNotifications($event)"
                :checked="settings.notifications.signals"
              ></ion-toggle>
            </ion-item>
            <ion-item>
              <ion-label>{{ lang.settings.news_notifications }}</ion-label>
              <ion-toggle
                slot="end"
                color="primary"
                @ionChange="setNewsNotifications($event)"
                :checked="settings.notifications.news"
              ></ion-toggle>
            </ion-item>

            <ion-item>
              <ion-label>{{ lang.settings.language }}</ion-label>
              <ion-select
                :value="selectedLang"
                interface="action-sheet"
                @ionChange="selectLanguage($event)"
              >
                <template v-for="(language, code) in languages" :key="code">
                  <ion-select-option :value="code" v-if="code == 'default'">{{
                    lang.settings.default
                  }}</ion-select-option>
                  <ion-select-option :value="code" v-else>{{
                    language
                  }}</ion-select-option>
                </template>
                <!-- <ion-select-option value="mute">Mute</ion-select-option>
                <ion-select-option value="mute_week"
                  >Mute for a week</ion-select-option
                >
                <ion-select-option value="mute_year"
                  >Mute for a year</ion-select-option
                > -->
              </ion-select>
            </ion-item>
          </ion-list>
          <ion-list>
            <ion-list-header
              ><h4>{{ lang.settings.about_us }}</h4></ion-list-header
            >
            <ion-item @click="contactMe()">
              <ion-label>{{ lang.settings.contact_me }}</ion-label>
              <ion-icon :icon="chatbubbleEllipsesSharp" slot="end"></ion-icon>
            </ion-item>
            <ion-item @click="rateUs()">
              <ion-label>{{ lang.settings.rate_us_text }}</ion-label>
              <ion-icon :icon="starSharp" slot="end" class="star"></ion-icon>
            </ion-item>
          </ion-list>
        </template>
      </div>
    </template>
  </base-layout-2>
</template>


<script>
// import {
//   IonButton,
//   IonIcon,
// } from "@ionic/vue";
import {
  arrowBack,
  checkmark,
  chatbubbleEllipsesSharp,
  starSharp,
  language,
} from "ionicons/icons";
import BaseLayout2 from "../layouts/Base2";
import {
  IonLabel,
  IonList,
  IonItem,
  IonListHeader,
  IonToggle,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";

import { Plugins } from "@capacitor/core";
const { NativeHelper } = Plugins;

export default {
  inject: ["store"],
  emits: ["closeModal"],
  data() {
    return {
      arrowBack,
      checkmark,
      chatbubbleEllipsesSharp,
      language,
      starSharp,
      filter: null,
      settings: null,
      languages: {
        default: true,
        ar: "عربي",
        bn: "বাংলা",
        zh: "中国人",
        en: "English",
        fr: "Français",
        de: "Deutsch",
        hi: "हिन्दी",
        id: "bahasa Indonesia",
        it: "Italiano",
        ja: "日本",
        ko: "한국인",
        mr: "मराठी",
        pt: "Português",
        ro: "Română",
        ru: "Русский",
        es: "Español",
        tr: "Türk",
        vi: "Tiếng Việt",
      },

      content: null,
    };
  },
  components: {
    "base-layout-2": BaseLayout2,
    IonLabel,
    IonList,
    IonItem,
    IonListHeader,
    IonToggle,
    IonIcon,
    IonSelect,
    IonSelectOption,
  },

  methods: {
    setIonContent(content) {
      //Get the page's ion-content element for scroll control
      this.content = content;
    },

    async setBuyNotifications(ev) {
      let self = this;
      let value;

      if (ev.detail.checked) {
        value = true;
      } else {
        value = false;
      }

      try {
        let resp = await window.axios({
          url: self.store.endpoint + "/set-signal-notifications",
          method: "post",
          data: {
            value,
            firebase_token: self.store.state.firebase_token,
            general_notifications: true
          },
        });

        if (resp.status == 200 && resp.data) {
          this.settings.notifications.signals = ev.detail.checked;
          this.store.setters.applySettings(this.settings);
        }
      } catch (e) {
        console.log(e);
      }
    },

    async setNewsNotifications(ev) {
      let self = this;
      try {
        let resp = await window.axios({
          url: self.store.endpoint + "/set-news-notifications",
          method: "post",
          data: {
            status: ev.detail.checked,
            firebase_token: self.store.state.firebase_token,
          },
        });

        if (resp.status == 200 && resp.data) {
          this.settings.notifications.news = ev.detail.checked;
          this.store.setters.applySettings(this.settings);

          if (ev.detail.checked) {
            this.store.setters.setBanner("news_notifications", false);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },

    async selectLanguage(event) {
      let defaultLang = await this.store.getters.defaultLang();

      if (event.detail.value == "default") {
        this.settings.lang = defaultLang;
        this.settings.systemLangOption = true;
      } else {
        this.settings.lang = event.detail.value;
        this.settings.systemLangOption = false;
      }

      this.store.setters.applySettings(this.settings);

      await NativeHelper.storeInSharedPrefs({
        key: "appLanguage",
        value: this.settings.lang,
      });

      // await NativeHelper.setLanguage({
      //   language: this.settings.lang,
      // });
    },

    contactMe() {
      window.location.href = "mailto:dev@bullishsignals.com";
    },

    rateUs() {
      window.location.href = this.appStoreUrl;
    },
  },

  computed: {
    osVersion() {
      return this.store.state.deviceData.osVersion;
    },

    selectedLang() {
      if (this.settings.systemLangOption) {
        return "default";
      } else {
        return this.settings.lang;
      }
    },

    sell_notification_options() {
      let self = this;
      return {
        all: self.lang.sell_notification_option_1 ?? "All signals",
        favorites: self.lang.sell_notification_option_2 ?? "Favorites only",
        none: self.lang.sell_notification_option_3 ?? "None",
      };
    },
  },

  created() {
    this.settings = JSON.parse(JSON.stringify(this.store.state.settings));
  },
};
</script>


<style scoped lang="scss">
.page-container {
  padding-top: 20px;
  border-top-left-radius: 40px;
  background: #fafafc;
  overflow: auto;
  @include toolbar-content-size-1;
}

ion-list.exchanges {
  border-top-left-radius: 30px;
}

ion-chip {
  font-weight: bold;
  border: 1px solid;
}

ion-chip.disabled {
  // position: relative;
}

ion-chip.active {
  // position: relative;
}

ion-icon.star {
  color: #ffd845;
}
</style>